/*!

=========================================================
* Material Kit PRO React - v1.9.0 based on Material Kit PRO v2.0.2 (Bootstrap 4.0.0 Final Edition)
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.btn-file {
  position: relative;
  overflow: hidden;
  vertical-align: middle; }

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  direction: ltr; }

.fileinput {
  text-align: center;
  display: inline-block;
  margin-bottom: 9px; }
  .fileinput input[type="file"] {
    display: none; }

.fileinput .form-control {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text; }

.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 360px;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }
  .fileinput .thumbnail.img-circle {
    border-radius: 50%;
    max-width: 100px; }

.fileinput .thumbnail > img {
  max-height: 100%;
  width: 100%; 
}

.fileinput .btn {
  vertical-align: middle; }

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none; }

.fileinput-inline .fileinput-controls {
  display: inline; }

.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle; }

.form-control .fileinput-filename {
  vertical-align: bottom; }

.fileinput.input-group {
  display: table; }

.fileinput.input-group > * {
  position: relative;
  z-index: 2; }

.fileinput.input-group > .btn-file {
  z-index: 1; }

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0; }

.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0; }

.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0; }

.form-group.has-warning .fileinput .fileinput-preview {
  color: #ff9800; }

.form-group.has-warning .fileinput .thumbnail {
  border-color: #ff9800; }

.form-group.has-error .fileinput .fileinput-preview {
  color: #f44336; }

.form-group.has-error .fileinput .thumbnail {
  border-color: #f44336; }

.form-group.has-success .fileinput .fileinput-preview {
  color: #4caf50; }

.form-group.has-success .fileinput .thumbnail {
  border-color: #4caf50; }

.input-group-addon:not(:first-child) {
  border-left: 0; }

.thumbnail {
  border: 0 none;
  border-radius: 0;
  padding: 0; }

@keyframes Floatingx {
  from {
    -webkit-transform: translate(50px, 0px); }
  65% {
    -webkit-transform: translate(0, 0); }
  to {
    -webkit-transform: translate(50px, 0px); } }


@keyframes Floatingy {
  from {
    -webkit-transform: translate(0, 0px); }
  65% {
    -webkit-transform: translate(50px, 0); }
  to {
    -webkit-transform: translate(0, 0px); } }


html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

* {
  letter-spacing: normal !important; }

body {
  font-size: 1rem; }

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit; }

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px; }

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

h1 {
  font-size: 3.3125rem;
  line-height: 1.15em; }

h2 {
  font-size: 2.25rem;
  line-height: 1.5em; }

h3 {
  font-size: 1.5625rem;
  line-height: 1.4em; }

h4 {
  font-size: 1.125rem;
  line-height: 1.5em; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.55em; }

h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500; }

p {
  font-size: 14px;
  margin: 0 0 10px; }

b,
strong {
  font-weight: 700; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

body {
  /* background-color: #0a0615; */
  background-color: #E2E2E2;

  color: #3c4858;
  margin: 0;
  font-size: 1rem;
  text-align: left; }

legend {
  border-bottom: 0; }

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box; }
  *:focus {
    outline: 0; }

a {
  color: #9c27b0;
  text-decoration: none;
  background-color: transparent; }
  a:hover, a:focus {
    color: #89229b;
    text-decoration: none; }

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400; }

small {
  font-size: 75%;
  color: #777;
  font-weight: 400; }

img {
  vertical-align: middle;
  border-style: none; }

form {
  margin-bottom: 1.125rem; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

#images h4 {
  margin-bottom: 30px; }

#root {
  overflow: hidden; }

#cd-vertical-nav {
  position: fixed;
  right: -78px;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 4; }

#cd-vertical-nav ul {
  list-style: none;
  padding: 0; }

#cd-vertical-nav li {
  text-align: right; }

#cd-vertical-nav a {
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%; }

#cd-vertical-nav a:hover span,
#cd-vertical-nav a.is-selected .cd-dot {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

#cd-vertical-nav .cd-dot {
  position: relative;
  top: 8px;
  right: calc(100% - 15px);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #995581;
  -webkit-transition: -webkit-transform 0.2s, background-color 0.5s;
  -moz-transition: -moz-transform 0.2s, background-color 0.5s;
  transition: transform 0.2s, background-color 0.5s;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

#cd-vertical-nav a span {
  float: right;
  display: inline-block;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6); }

#cd-vertical-nav .cd-label {
  position: relative;
  margin-right: 10px;
  padding: 4px 14px;
  color: white;
  background: rgba(0, 0, 0, 0.53);
  font-size: 10px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 600;
  opacity: 0;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%; }

#cd-vertical-nav a:after {
  content: "";
  display: table;
  clear: both; }

#cd-vertical-nav a:hover .cd-label {
  opacity: 1;
  left: -100%;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s; }

/*! nouislider - 14.0.2 - 6/28/2019 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
/*

This file was modified by Creative-Tim

*/
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat; }

.noUi-connect {
  height: 100%;
  width: 100%; }

.noUi-origin {
  height: 10%;
  width: 10%; }

/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0; }

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute; }

.noUi-touch-area {
  height: 100%;
  width: 100%; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 2px;
  margin: 15px 0; }

.noUi-horizontal .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -10px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  border: 1px solid #9c27b0;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 15px;
  height: 15px;
  left: 0px;
  top: -7px; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -4px;
  left: auto; }

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background-color: #c8c8c8;
  border-radius: 3px; }

.noUi-connects {
  border-radius: 3px; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.noUi-target .noUi-handle {
  border: 1px solid #333; }

.noUi-target.slider-primary .noUi-connect, .noUi-target.slider-primary.noUi-connect {
  background-color: #9c27b0; }

.noUi-target.slider-primary .noUi-handle {
  border-color: #9c27b0; }

.noUi-target.slider-info .noUi-connect, .noUi-target.slider-info.noUi-connect {
  background-color: #00bcd4; }

.noUi-target.slider-info .noUi-handle {
  border-color: #00bcd4; }

.noUi-target.slider-success .noUi-connect, .noUi-target.slider-success.noUi-connect {
  background-color: #4caf50; }

.noUi-target.slider-success .noUi-handle {
  border-color: #4caf50; }

.noUi-target.slider-warning .noUi-connect, .noUi-target.slider-warning.noUi-connect {
  background-color: #ff9800; }

.noUi-target.slider-warning .noUi-handle {
  border-color: #ff9800; }

.noUi-target.slider-danger .noUi-connect, .noUi-target.slider-danger.noUi-connect {
  background-color: #f44336; }

.noUi-target.slider-danger .noUi-handle {
  border-color: #f44336; }

.noUi-target.slider-rose .noUi-connect, .noUi-target.slider-rose.noUi-connect {
  background-color: #e91e63; }

.noUi-target.slider-rose .noUi-handle {
  border-color: #e91e63; }

  .noUi-target.slider-gray .noUi-connect, .noUi-target.slider-rose.noUi-connect {
    background-color: #7a7a7a; }
  
  .noUi-target.slider-gray .noUi-handle {
    border-color: #7a7a7a; }
/*!
* https://github.com/YouCanBookMe/react-datetime
*/
.rdt {
  position: relative; }
  .rdt .rdtPicker {
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0; }
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0; }
  .rdt input.form-control {
    border: 0;
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.42857;
    display: block;
    width: 100%;
    color: #555; }
  .rdt input.form-control:focus {
    outline: none;
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px; }
  .rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #ffffff;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent; }
  .rdtPicker:after {
    border-bottom: 0.4em solid #ffffff;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px; }

.rdtPicker {
  display: block;
  top: 40px; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #fff !important;
  border-collapse: collapse; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px; }

.rdtPicker td {
  cursor: pointer; }

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%; }
  .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
    background-color: #9c27b0 !important;
    color: #fff;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6); }

.rdtDays tr .dow {
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: 5px;
  padding-top: 10px; }

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #bdbdbd; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px; }

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #495057; }

.rdtPicker .rdtTimeToggle {
  color: #9c27b0; }

.rdtPicker .rdtTime th.rdtSwitch {
  color: #9c27b0; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%; }

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }
  .rdtCounter .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent; }

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #9c27b0;
  border-radius: 50%;
  border: 1px solid #9c27b0; }

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px; }

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #9c27b0;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee; }

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px; }

.rdtMonths,
.rdtYears {
  padding-bottom: 10px; }
  .rdtMonths .rdtMonth,
  .rdtMonths .rdtYear,
  .rdtYears .rdtMonth,
  .rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center; }
    .rdtMonths .rdtMonth.rdtActive,
    .rdtMonths .rdtYear.rdtActive,
    .rdtYears .rdtMonth.rdtActive,
    .rdtYears .rdtYear.rdtActive {
      background-color: #9c27b0 !important;
      color: #fff; }

.image-gallery-icon {
  color: #fff;
  transition: all .2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a); }
  @media (min-width: 768px) {
    .image-gallery-icon:hover {
      color: #337ab7; }
      .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1); } }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7; }

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none; }

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px; }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 36px;
    width: 36px; }
  @media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 15px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px; } }
  @media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 10px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px; } }

.image-gallery-fullscreen-button {
  right: 0; }

.image-gallery-play-button {
  left: 0; }

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%); }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px; }
  @media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 72px;
      width: 36px; } }
  @media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 24px; } }
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none; }

.image-gallery-left-nav {
  left: 0; }

.image-gallery-right-nav {
  right: 0; }

.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative; }
  .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5; }
    .image-gallery.fullscreen-modal .image-gallery-content {
      top: 50%;
      transform: translateY(-50%); }

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0; }
  .image-gallery-content.fullscreen {
    background: #000; }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px); }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh; }

.image-gallery-slide-wrapper {
  position: relative; }
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px); }
    @media (max-width: 768px) {
      .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
        width: calc(100% - 87px); } }
  .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl; }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center; }

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .image-gallery-slide.center {
    position: relative; }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain; }
  .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal; }
    @media (max-width: 768px) {
      .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px; } }

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4; }
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center; }
  .image-gallery-bullets .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: background .2s ease-out; }
    @media (max-width: 768px) {
      .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px; } }
    @media (max-width: 480px) {
      .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px; } }
    .image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
      background: #337ab7;
      transform: scale(1.1); }
    .image-gallery-bullets .image-gallery-bullet.active {
      background: #fff; }

.image-gallery-thumbnails-wrapper {
  position: relative; }
  .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        width: 81px; } }
    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0; }
      .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0; }
        .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 5px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px; } }

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnails {
      padding: 3px 0; } }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .45s ease-out;
    white-space: nowrap; }

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail {
      border: 3px solid transparent;
      width: 81px; } }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px; }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative; }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0; }
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
        border: 3px solid #337ab7; } }

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail-label {
      font-size: .8em;
      line-height: .8em; } }

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4; }
  @media (max-width: 768px) {
    .image-gallery-index {
      font-size: .8em;
      padding: 5px 10px; } }

.image-gallery-left-nav,
.image-gallery-right-nav {
  position: absolute;
  cursor: pointer;
  z-index: 100;
  opacity: 0.5;
  bottom: -40%;
  top: auto;
  padding: 0 !important;
  color: #fff;
  font-size: 5em;
  outline: none;
  background-color: transparent;
  border: 0;
  transform: translateY(-50%); }
  .image-gallery-left-nav:before, .image-gallery-left-nav:hover:before,
  .image-gallery-right-nav:before,
  .image-gallery-right-nav:hover:before {
    color: #3c4858;
    text-shadow: none; }
  .image-gallery-left-nav:before,
  .image-gallery-right-nav:before {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased; }

.image-gallery-left-nav {
  left: -20px; }
  .image-gallery-left-nav::before {
    content: "chevron_left"; }

.image-gallery-right-nav {
  right: -20px; }
  .image-gallery-right-nav::before {
    content: "chevron_right"; }

.image-gallery-thumbnail {
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  position: relative;
  line-height: 0px;
  width: 125px;
  border: none !important; }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin: 0 !important; }
  .image-gallery-thumbnail img {
    max-width: 100%;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px; }

.image-gallery-thumbnail-label {
  display: none !important; }

.image-gallery-thumbnails {
  padding: 0 !important;
  overflow: hidden;
  width: 100%; }

.image-gallery-thumbnails-container {
  position: relative;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  text-align: center; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }
  @media (min-width: 768px) {
    .slick-slider .slick-caption {
      display: block !important; } }
  .slick-slider .slick-caption {
    padding-bottom: 45px;
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    color: #ffffff;
    text-align: center;
    z-index: 3;
    display: none; }
  .slick-slider .slick-slide > div:first-child {
    position: relative; }
  .slick-slider .slick-icons {
    position: relative;
    top: 5px; }
  .slick-slider .slick-image {
    width: 100% !important;
    display: inline-flex !important; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ""; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  height: 100%;
  padding: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  background: transparent;
  width: 15%;
  z-index: 2;
  opacity: 0.5; }

.slick-prev {
  left: 0; }
  .slick-prev::before {
    content: "\f053";
    /* content: "\f359"; */

    font-weight: 600;
    font-family: Font Awesome\ 5 Free;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: white;
    font-size: 30px;
    width: 100%; }

.slick-next {
  right: 0; }
  .slick-next::before {
    content: "\f054";
    font-weight: 600;
    font-family: Font Awesome\ 5 Free;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: #fff;
    font-size: 30px;
    width: 100%; }

.slick-list {
  z-index: 1; }

.slick-dots {
  margin-top: 0;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 5px;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  z-index: 3; }

.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer; }

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }
  .slick-dots li button::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: "\2022";
    text-align: center;
    opacity: 1;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    transition: all 300ms linear; }

.slick-dots li.slick-active button:before {
  width: 15px;
  height: 15px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  top: -3px; }

.react-tagsinput {
  display: inline-block;
  padding: 4px 6px;
  max-width: 100%;
  line-height: 22px; }

.react-tagsinput-tag {
  cursor: pointer;
  margin: 5px 3px 5px 0;
  position: relative;
  padding: 3px 8px;
  border-radius: 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1.5em;
  padding-left: 0.8em; }

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold; }

.react-tagsinput-tag a::before {
  font-family: Font Awesome\ 5 Free;
  content: "\f00d";
  padding: 0px 2px;
  font-weight: 900; }

.react-tagsinput-tag a {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0px;
  opacity: 0;
  background-color: transparent;
  color: #ffffff; }

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px; }

.react-tagsinput .react-tagsinput-tag {
  -webkit-transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s;
  background-color: #999; }
  .react-tagsinput .react-tagsinput-tag:hover {
    padding-right: 22px; }
    .react-tagsinput .react-tagsinput-tag:hover a {
      opacity: 1;
      padding-right: 4px;
      background-color: transparent;
      color: #ffffff; }
  .react-tagsinput .react-tagsinput-tag.primary {
    background-color: #9c27b0; }
  .react-tagsinput .react-tagsinput-tag.info {
    background-color: #00bcd4; }
  .react-tagsinput .react-tagsinput-tag.success {
    background-color: #4caf50; }
  .react-tagsinput .react-tagsinput-tag.warning {
    background-color: #ff9800; }
  .react-tagsinput .react-tagsinput-tag.danger {
    background-color: #f44336; }
  .react-tagsinput .react-tagsinput-tag.rose {
    background-color: #e91e63; }
  .react-tagsinput .react-tagsinput-tag.default {
    background-color: #999; }
